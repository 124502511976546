/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable max-len */
import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import EventBus from 'eventing-bus';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { uniq } from 'lodash';

import { getPathLanguage, getLanguage } from '../../../utils/params';
import ISN from '../../../assets/Logo_ISN.svg';
import ToggleMenu from '../../../assets/images/toggle-menu.svg';
import CloseMenu from '../../../assets/images/Icon_close.svg';
import { FeatherHeart, FeatherClock } from '../icons';
import { isMobile } from '../../../utils/constants';
import { ChevronRightIcon } from '../icons';
import * as types from '../../../modules/favoriteHistoryPage/favorite.action';

const Navigator = props => {
  const { t } = useTranslation();
  const location = useLocation();
  const language = getLanguage(location);
  const localeLanguage = getPathLanguage(location);
  const [isShowCollapse, setIsShowCollapse] = useState(false);

  const currentMenu = [
    {
      link_to: `${localeLanguage}`,
      link_label: t('navigation.home'),
      active:
        location.pathname === '/' ||
        location.pathname === localeLanguage ||
        location.pathname.includes(`${localeLanguage}/residence`) ||
        location.pathname.includes(`${localeLanguage}/office`),
    },
    {
      link_to: `${localeLanguage}/useful-information/all`,
      link_label: t('navigation.usefull-information'),
      active: location.pathname.includes(`${localeLanguage}/useful-information`),
    },
    {
      link_to: isMobile ? `${localeLanguage}/blogs` : `${localeLanguage}/blogs/all`,
      link_label: t('navigation.blog'),
      active: location.pathname.includes(`${localeLanguage}/blog`),
    },
    {
      link_to: `${localeLanguage}/for-owners`,
      link_label: t('navigation.for-owners'),
      active: location.pathname.includes(`${localeLanguage}/for-owners`),
    },
    {
      link_to: `${localeLanguage}/about-isn`,
      link_label: t('navigation.about-isn'),
      active: location.pathname.includes(`${localeLanguage}/about-isn`),
    },
  ];
  const { favorites } = props.favorites;
  const { histories } = props.favorites;
  const likesResidence = localStorage.getItem('RESIDENCES_LIKE')
    ? localStorage.getItem('RESIDENCES_LIKE').split(',')
    : [];
  const likesOffice = localStorage.getItem('OFFICES_LIKE')
    ? localStorage.getItem('OFFICES_LIKE').split(',') : [];
  const [likesData, setLikesData] = useState();

  const newHistoryData = localStorage.getItem('HISTORY')
    ? localStorage.getItem('HISTORY').split(',')
    : [];
  const [historyData, setHistoryData] = useState();

  const closeCollapseMobile = () => {
    setIsShowCollapse(false);
    EventBus.publish('checkBodyScroll', false);
  };
  const localHistory = localStorage.getItem('HISTORY');

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    setHistoryData(localStorage.getItem('HISTORY') ? localStorage.getItem('HISTORY').split(',') : []);

    return () => {
      document.removeEventListener('click', handleClickOutside);
      EventBus.unregisterCallbacksForEvent('RESET_LIKE');
    };
    // eslint-disable-next-line
  }, [localHistory]);

  useEffect(() => {
    const propertyId = [...likesResidence, ...likesOffice] || [];
    const paramsFavorite = { language, pageIndex: 1, pageSize: 9, propertyId: propertyId.toString() };
    if (propertyId && propertyId.length > 0) {
      props.fetchFavoritesRequest(paramsFavorite);
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
      EventBus.unregisterCallbacksForEvent('RESET_LIKE');
    };
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const propertyHistoryId = [...newHistoryData] || [];
    const paramsHistory = { language, pageIndex: 1, pageSize: 9, propertyId: propertyHistoryId.toString() };
    if (propertyHistoryId && propertyHistoryId.length > 0) {
      props.fetchHistoriesRequest(paramsHistory);
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
      EventBus.unregisterCallbacksForEvent('RESET_LIKE');
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (favorites && favorites.items && favorites.items.length) {
      const favoriteIdsResidence = favorites.items.filter(x => x.propertyType === "residence").map(x => x.id);
      const favoriteIdsOffice = favorites.items.filter(x => x.propertyType === "office").map(x => x.id);
      localStorage.setItem('RESIDENCES_LIKE', favoriteIdsResidence.toString());
      localStorage.setItem('OFFICES_LIKE', favoriteIdsOffice.toString());
      setLikesData(uniq([...favoriteIdsResidence, ...favoriteIdsOffice]))
    } else {
      localStorage.removeItem('OFFICES_LIKE');
      localStorage.removeItem('RESIDENCES_LIKE');
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
      EventBus.unregisterCallbacksForEvent('RESET_LIKE');
    };
  }, [favorites]);

  useEffect(() => {
    if (histories && histories.items && histories.items.length) {
      const historyId = histories.items.map(x => x.id);
      localStorage.setItem('HISTORY', historyId.toString());
      setHistoryData(uniq(historyId));
    } else {
      localStorage.removeItem('HISTORY');
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
      EventBus.unregisterCallbacksForEvent('RESET_LIKE');
    };
  }, [histories]);

  EventBus.on('RESET_LIKE', data => {
    if (data) {
      const likesResidence = localStorage.getItem('RESIDENCES_LIKE')
        ? localStorage.getItem('RESIDENCES_LIKE').split(',')
        : [];
      const likesOffice = localStorage.getItem('OFFICES_LIKE') ? localStorage.getItem('OFFICES_LIKE').split(',') : [];

      const newHistoryData = localStorage.getItem('HISTORY') ? localStorage.getItem('HISTORY').split(',') : [];
      setLikesData(uniq([...likesResidence, ...likesOffice]));
      setHistoryData(uniq(newHistoryData));
    }
  });

  const handleClickOutside = event => {
    if (event.target.className.toString().includes('menu-responsive-overlay show')) closeCollapseMobile();
  };

  const likesComponent = useMemo(() => {
    return (
      <div className="wrap-icon-navigation">
        <FeatherHeart />
        <div className="count-number">
          {likesData?.length && likesData?.length > 999 ? '999+' : likesData?.length ? likesData?.length : 0}
        </div>
      </div>
    );

    // eslint-disable-next-line
  }, [likesData]);

  const historyComponent = useMemo(() => {
    return (
      <div className="wrap-icon-navigation">
        <FeatherClock />
        <div className="count-number count-history">{historyData?.length ? historyData?.length : 0}</div>
      </div>
    );

    // eslint-disable-next-line
  }, [historyData]);

  useEffect(() => {
    const fbMessDom = document.getElementsByClassName('fb_dialog_content');

    if (isShowCollapse && isMobile) {
      if (fbMessDom && fbMessDom[0]) {
        const listIframe = fbMessDom[0].querySelectorAll('iframe');
        listIframe.forEach(item => {
          item.style.display = 'none';
        });
      }
    } else {
      if (fbMessDom && fbMessDom[0]) {
        const listIframe = fbMessDom[0].querySelectorAll('iframe');
        listIframe.forEach(item => {
          item.style.display = '';
        });
      }
    }

    // eslint-disable-next-line
  }, [isShowCollapse]);

  return (
    <>
      <section className="section-header-logo header-logo jp-font-sawarabi-gothic" style={{ fontWeight: '500' }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-4 col-md-2">
              <div className="header-logo-image">
                <Link to={currentMenu[0].link_to}>
                  {/* <img alt="ISN Vietnamhousing" className="w-100" src={ISN} /> */}
                  <LazyLoadImage className="w-100" alt="ISN Vietnamhousing" src={ISN} effect="blur" />
                </Link>
              </div>
            </div>
            <div className="col-8 col-md-10 d-block d-lg-none">
              <div
                className={`toggle-menu ${!isShowCollapse ? 'collapsed' : 'close-icon'}`}
                id="toggle-menu"
              >
                <li className='menu-item menu-item-magazine menu-item-magazine-mobile'>{t('useful_page.magazine_menu_name')}
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"
                    className="feather feather-chevron-down">
                    <polyline points="6 9 12 15 18 9"></polyline>
                  </svg>
                  <ul className='sub-menu-item-magazine'>
                    {/* eslint-disable-next-line max-len,react/jsx-no-target-blank */}
                    <li><a href='https://crm.vietnamhousing.vn/images/danang_map.pdf' target='_blank'>{language == 'jp'? 'ダナン生活地図' : 'Da Nang Map'}</a></li>
                    {/* eslint-disable-next-line max-len,react/jsx-no-target-blank */}
                    <li><a href='https://crm.vietnamhousing.vn/images/haiphong_map.pdf' target='_blank'>{language == 'jp'? 'ハイフォン生活地図' : 'Hai Phong Map'}</a></li>
                    {/* eslint-disable-next-line max-len,react/jsx-no-target-blank */}
                    <li><a href='https://crm.vietnamhousing.vn/images/2025.pdf' target='_blank'>2025</a></li>
                    {/* eslint-disable-next-line max-len,react/jsx-no-target-blank */}
                    <li><a href='https://crm.vietnamhousing.vn/images/2024.pdf' target='_blank'>2024</a></li>
                    {/* eslint-disable-next-line max-len,react/jsx-no-target-blank */}
                    <li><a href='https://crm.vietnamhousing.vn/images/2023.pdf' target='_blank'>2023</a></li>
                    {/* eslint-disable-next-line max-len,react/jsx-no-target-blank */}
                    <li><a href='https://crm.vietnamhousing.vn/images/2022.pdf' target='_blank'>2022</a></li>
                  </ul>
                </li>
                {/* <img className="toggle-menu-icon" src={ToggleMenu} alt="" /> */}
                <LazyLoadImage className="toggle-menu-icon" alt="" src={ToggleMenu} effect="blur" onClick={() => {
                  setIsShowCollapse(!isShowCollapse);
                  EventBus.publish('checkBodyScroll', !isShowCollapse);
                }} />
                {/* <img className="close-menu-icon" src={CloseMenu} alt="" /> */}
                <LazyLoadImage className="close-menu-icon" alt="" src={CloseMenu} effect="blur" onClick={() => {
                  setIsShowCollapse(!isShowCollapse);
                  EventBus.publish('checkBodyScroll', !isShowCollapse);
                }} />
              </div>
            </div>
            <div className="col-6 offset-6 d-block d-lg-none">
              <div
                className={`menu-responsive collapse jp-font-sawarabi-gothic ${!isShowCollapse ? '' : 'show'}`}
                id="collapseOne"
              >
                <div className="top-menu">
                  <div className="col-6 pr-0 text-center favourite-padding">
                    <div className="wrap-icon-navigation">
                      <Link to={`${localeLanguage}/favorites`} onClick={closeCollapseMobile}>
                        <FeatherHeart />
                        <div className="count-number">
                          {likesData?.length && likesData?.length > 999
                            ? '999+'
                            : likesData?.length
                              ? likesData?.length
                              : 0}
                        </div>
                      </Link>
                    </div>
                    <div className="favorite-link">
                      <Link to={`${localeLanguage}/favorites`} onClick={closeCollapseMobile}>
                        {t('navigation.favorites')}
                      </Link>
                    </div>
                  </div>
                  <div className="col-6 pl-0 text-center history-padding">
                    <div className="wrap-icon-navigation">
                      <Link to={`${localeLanguage}/histories`} onClick={closeCollapseMobile}>
                        <FeatherClock />
                        <div className="count-number count-history">{historyData?.length ? historyData?.length : 0}</div>
                      </Link>
                    </div>

                    <div className="favorite-link">
                      <Link to={`${localeLanguage}/histories`} onClick={closeCollapseMobile}>
                        {t('navigation.histories')}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="menu">
                  {currentMenu
                    ? currentMenu.map((menu, i) => (
                      <div className={`menu-item ${menu.active ? 'actived' : ''}`} key={i}>
                        <Link to={menu.link_to} onClick={closeCollapseMobile}>
                          {menu.link_label}
                        </Link>
                        <div className="icon-chervon-right">
                          <ChevronRightIcon />
                        </div>
                      </div>
                    ))
                    : ''}
                </div>
              </div>
            </div>
            <div className={`menu-responsive-overlay ${!isShowCollapse ? '' : 'show'}`}></div>
            <div className="co-12 col-md-8">
              <div className="nav-main-menu d-none d-lg-block">
                <ul className="main-menu">
                  {currentMenu
                    ? currentMenu.map((menu, i) => (
                      <li className={`menu-item ${menu.active ? 'active' : ''} `} key={i}>
                        <Link to={menu.link_to}>{menu.link_label}</Link>
                      </li>
                    ))
                    : ''}
                  <li className='menu-item menu-item-magazine'>{t('useful_page.magazine_menu_name')}
                    {/* eslint-disable-next-line max-len */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"
                      className="feather feather-chevron-down">
                      <polyline points="6 9 12 15 18 9"></polyline>
                    </svg>
                    <ul className='sub-menu-item-magazine'>
                      {/* eslint-disable-next-line max-len,react/jsx-no-target-blank */}
                      <li><a href='https://crm.vietnamhousing.vn/images/danang_map.pdf' target='_blank'>{language == 'jp'? 'ダナン生活地図' : 'Da Nang Map'}</a></li>
                      {/* eslint-disable-next-line max-len,react/jsx-no-target-blank */}
                      <li><a href='https://crm.vietnamhousing.vn/images/haiphong_map.pdf' target='_blank'>{language == 'jp'? 'ハイフォン生活地図' : 'Hai Phong Map'}</a></li>
                      {/* eslint-disable-next-line max-len,react/jsx-no-target-blank */}
                      <li><a href='https://crm.vietnamhousing.vn/images/2025.pdf' target='_blank'>2025</a></li>
                      {/* eslint-disable-next-line max-len,react/jsx-no-target-blank */}
                      <li><a href='https://crm.vietnamhousing.vn/images/2024.pdf' target='_blank'>2024</a></li>
                      {/* eslint-disable-next-line max-len,react/jsx-no-target-blank */}
                      <li><a href='https://crm.vietnamhousing.vn/images/2023.pdf' target='_blank'>2023</a></li>
                      {/* eslint-disable-next-line max-len,react/jsx-no-target-blank */}
                      <li><a href='https://crm.vietnamhousing.vn/images/2022.pdf' target='_blank'>2022</a></li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div className="co-12 col-md-2">
              <ul className="favorites-histories d-none d-lg-block">
                <li
                  className={`favorites-histories-menu ${location.pathname.includes(`${localeLanguage}/favorites`) ? 'actived' : ''
                    }`}
                >
                  <Link to={`${localeLanguage}/favorites`}>
                    {likesComponent}
                    {t('navigation.favorites')}
                  </Link>
                </li>
                <li
                  className={`favorites-histories-menu ${location.pathname.includes(`${localeLanguage}/histories`) ? 'actived' : ''
                    }`}
                >
                  <Link to={`${localeLanguage}/histories`}>
                    {historyComponent}
                    {t('navigation.histories')}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
const mapStateToProps = state => ({
  ...state,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchFavoritesRequest: types.fetchFavoritesRequest,
      fetchHistoriesRequest: types.fetchHistoriesRequest,
    },
    dispatch,
  );
export default connect(mapStateToProps, mapDispatchToProps)(Navigator);
// export default Navigator;
